import React, { useEffect, useRef, useState } from 'react';

import Head from 'next/head';
import PropTypes from 'prop-types';

import { withTranslation } from 'next-i18next';

import { useAuth } from '@context/UseAuth';
import GlobalLayout from '@components/layouts/GlobalLayout';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { Portal } from 'react-portal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import BasicSlider from '@components/landing/index/BasicSlider';
import CostItem from '@components/landing/CostItem';
import cn from 'classnames';
import useNavBarColorChange from '@hooks/useNavBarColorChange';
import Link from 'next/link';
import VillaMarketTestimonial from '@components/landing/VillaMarketTestimonial';

const clientImages = [
  {
    path: '/images/landing/index/clients/width/udenna.png',
    alt: 'Udenna',
    width: 217,
  },
  {
    path: '/images/landing/index/clients/width/villa_market.png',
    alt: 'Villa market',
    width: 278,
  },
  {
    path: '/images/landing/index/clients/width/dcme.png',
    alt: 'DCME',
    width: 199,
  },
  {
    path: '/images/landing/index/clients/width/share_investor.png',
    alt: 'Share Investor',
    width: 260,
  },
  {
    path: '/images/landing/index/clients/width/squeaky_cat.png',
    alt: 'Squeaky Cat',
    width: 165,
  },
  {
    path: '/images/landing/index/clients/width/ecosys.png',
    alt: 'Ecosys',
    width: 147,
  },
  {
    path: '/images/landing/index/clients/width/donatela.png',
    alt: 'Donatela',
    width: 195,
  },
  {
    path: '/images/landing/index/clients/width/unicorn_tech.png',
    alt: 'Unicorn Tech',
    width: 277,
  },
  {
    path: '/images/landing/index/clients/width/ph_resorts_group.png',
    alt: 'PH resorts group',
    width: 192,
  },
  {
    path: '/images/landing/index/clients/width/unalytics.png',
    alt: 'Unalytics',
    width: 228,
  },
  {
    path: '/images/landing/index/clients/width/synesis_one.png',
    alt: 'Synesis one',
    width: 242,
  },
  {
    path: '/images/landing/index/clients/pruksa.png',
    alt: 'Pruksa',
    width: 162,
  },
];

const partnerSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  variableWidth: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const QUESTION_TAB = {
  DEVELOPER: 0,
  BUSINESS: 1,
};

const Content = ({ t }) => {
  const { user: userInfo, isUserCompleted } = useAuth();
  const dataRef = useRef();
  const lightNavRef = useRef();
  const [questionTab, setQuestionTab] = useState(QUESTION_TAB.BUSINESS);
  const [dataInt, setDataInt] = useState(50);
  const [timeInt, setTimeInt] = useState(1);
  const [manpowerInt, setManpowerInt] = useState(100);
  const [isAnimationStart, setIsAnimationStart] = useState(false);
  const [isCircleStart, setIsCircleStart] = useState(false);
  const [isManpowerStart, setIsManpowerStart] = useState(false);
  const [showCommonQuestions, setShowCommonQuestions] = useState({
    developer: {
      1: true,
      2: false,
      3: false,
    },
    business: { 1: true, 2: false, 3: false, 4: false },
  });

  useNavBarColorChange(lightNavRef);

  const getWindowWidth = () => {
    if (typeof window === 'undefined') return;

    const getWidth = () =>
      Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    return getWidth();
  };

  const useWindowDimension = () => {
    const [screenWidth, setScreenWidth] = useState(getWindowWidth());

    useEffect(() => {
      const handleScreenSize = () => {
        setTimeout(() => {
          setScreenWidth(getWindowWidth);
        }, 0);
      };

      window.addEventListener('resize', handleScreenSize);
      return () => window.removeEventListener('resize', handleScreenSize);
    }, []);
    return screenWidth;
  };

  const screenWidth = useWindowDimension();

  const handleScroll = () => {
    const getOffsetTop = (el) =>
      window.pageYOffset + el.getBoundingClientRect().top;
    if (dataRef && dataRef.current) {
      const { current } = dataRef;
      const winHeight = window.innerHeight;
      const windowScrollTop = window.scrollY;
      const windowScrollBottom = winHeight + windowScrollTop;
      const elHeight = current.offsetHeight;
      const elTop = getOffsetTop(current);
      const elBottom = elTop + elHeight;
      if (screenWidth < 712) {
        if (windowScrollBottom - elBottom >= 208) {
          setIsCircleStart(true);
        }
        if (windowScrollBottom - elBottom >= -208) {
          setIsManpowerStart(true);
        }
      }
      if (windowScrollBottom - elBottom >= 24) {
        setIsAnimationStart(true);
        setIsManpowerStart(true);
      }
    }
  };

  // Common questions
  const handleQuestionClick = (group, index) => {
    setShowCommonQuestions((prevState) => ({
      ...prevState,
      [group]: {
        ...prevState[group],
        [index]: !prevState[group][index],
      },
    }));
  };

  useEffect(() => {
    window.addEventListener('load', handleScroll);
    return () => window.removeEventListener('load', handleScroll);
  }, []);

  useEffect(() => {
    if (timeInt === 10) return;
    const scrollView = document.querySelector('.me-body-scroll');
    scrollView?.addEventListener('scroll', handleScroll);
    return () => scrollView?.removeEventListener('scroll', handleScroll);
  }, [timeInt]);

  useEffect(() => {
    if (manpowerInt === 100) return;
    const scrollView = document.querySelector('.me-body-scroll');
    scrollView?.addEventListener('scroll', handleScroll);
    return () => scrollView?.removeEventListener('scroll', handleScroll);
  }, [manpowerInt]);

  useEffect(() => {
    if (dataInt === 1) return;
    if (!isAnimationStart) return;
    const dataValueTimer = setTimeout(() => {
      setDataInt(dataInt - 1);
    }, 20);
    return () => clearTimeout(dataValueTimer);
  }, [dataInt, isAnimationStart]);

  useEffect(() => {
    if (timeInt === 10) return;
    if (!(screenWidth < 712) && !isAnimationStart) return;
    if (screenWidth < 712 && !isCircleStart) return;
    const timeValueTimer = setTimeout(() => {
      setTimeInt(timeInt + 1);
    }, 150);
    return () => clearTimeout(timeValueTimer);
  }, [timeInt, isAnimationStart, isCircleStart]);

  useEffect(() => {
    if (manpowerInt === 70) return;
    if (!(screenWidth < 712) && !isAnimationStart) return;
    if (screenWidth < 712 && !isManpowerStart) return;
    const timeValueTimer = setTimeout(() => {
      setManpowerInt(manpowerInt - 1);
    }, 30);
    return () => clearTimeout(timeValueTimer);
  }, [manpowerInt, isManpowerStart, isAnimationStart]);
  
  return (
    <div className="text-[16px]">
      <Head>
        <meta
          name="description"
          content="Transformative AI solution, tailored to your business objectives, empowered by Human Logic Intelligence (HLI)"
          key="desc"
        />
      </Head>
      <div className="main-section text-[#fff]">
        <div className="container px-6 max-w-[1248px] lg:py-[80px] sm:py-[60px] py-[40px] mx-auto h-[400px] lg:h-[528px]">
          <div>
            <div className="text-center text-[28px] sm:text-[36px] lg:text-[48px] lg:mb-[64px] sm:mb-[56px] mb-[48px]">
              <strong className="mb-4 sm:mb-2 lg:mb-6 inline-block font-extrabold">
                Build AI for <span className="text-[#fff080]">Business</span>
              </strong>
              <br />
              <p className="text-base sm:text-[20px] lg:text-[24px] leading-normal">
                <span className="block sm:inline">
                  Transformative AI solution,
                </span>{' '}
                tailored to your business objectives,
                <br /> empowered by{' '}
                <span className="block sm:inline">
                  Human Logic Intelligence (HLI)
                </span>
              </p>
            </div>
            <div className="text-center">
              {isUserCompleted && (
                <a
                  href={userInfo ? '/builder' : '/start'}
                  className="btn-idx-build --btn-build-white"
                  style={{ width: '184px' }}
                >
                  {userInfo ? 'Build your AI' : 'Get in Touch'}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#fafafa]">
        <div className="container px-6 max-w-[1248px] py-[40px] mx-auto">
          <div className="grid justify-center grid-cols-1 sm:grid-cols-[312px_312px] gap-y-12 gap-x-[40px] lg:gap-x-[200px]">
            <div className="text-center">
              <h2 className="text-[20px] sm:text-[24px] mb-5 bg-[#F0E593] inline-block font-extrabold leading-6">
                HLI-X
              </h2>
              <p className="mb-6">
                Multiply Business Success by Gaining High-quality Business
                Insight
              </p>
              <Link href="/solutions/hli-x">
                <a className="btn-learn-more">Learn More</a>
              </Link>
            </div>
            <div className="text-center">
              <h2 className="text-[20px] sm:text-[24px] mb-5 bg-[#B7E1E7] inline-block font-extrabold leading-6">
                HLI-Automate
              </h2>
              <p className="mb-6">
                Adapt to Dynamic Changes by Adding Human Brilliance to Business
                Automation
              </p>
              <Link href="/solutions/hli-automate">
                <a className="btn-learn-more">Learn More</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={lightNavRef}
        className="py-[40px] sm:py-[48px] px-2 xl:px-[50px] bg-white"
      >
        <div className="text-sm sm:text-base mb-[32px] sm:mb-[36px] text-center font-bold text-[#979797]">
          Growing Trust with Valued Customers and{' '}
          <span className="block sm:inline">Partners on Board</span>
        </div>
        <div className="h-[64px] sm:h-auto">
          <Slider {...partnerSettings}>
            {clientImages.map(({ path, alt, width = 205 }) => (
              <div
                key={alt}
                style={{ width }}
                className="px-2 sm:px-8 xl:px-[50px] box-content"
              >
                <img src={path} alt={alt} className="h-[60px] mx-auto" />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="bg-[#fafafa]">
        <div className="container px-6 max-w-[1248px] lg:py-[80px] sm:py-[60px] py-[40px] mx-auto">
          <h2 className="mb-4 sm:text-[32px] leading-[150%] font-extrabold">
            Empower System with Human Brilliance
          </h2>
          <div className="flex flex-col-reverse sm:flex-col">
            <p className="mb:0 sm:mb-[36px] leading-7" data-nosnippet>
              Human Logic Intelligence (HLI) mirrors how human experts make
              decisions by assembling logical steps like puzzle pieces,
              comprehending thought processes, and incorporating logical
              frameworks. Instead of solely relying on data, we aim to enhance
              Artificial Intelligence and automation systems by infusing them
              with human-like thinking, experience, and understanding.
            </p>
            <div className="text-center">
              <picture>
                {/* Use this image when screen width is 640 pixels or wider */}
                <source
                  media="(min-width: 640px)"
                  srcSet="/images/landing/index/mirror_human_logic.png"
                />
                <img
                  src="/images/landing/index/mirror_human_logic_mobile.png"
                  alt="alternate model"
                  className="max-w-[156px] sm:max-w-[860px] w-full mb-6 sm:mb-0"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#4d5d6f] text-white">
        <div
          className="container px-6 max-w-[1248px] lg:py-[80px] sm:py-[60px] py-[40px] mx-auto"
          data-nosnippet
        >
          <h2 className="mb-[40px] sm:mb-[60px] text-[24px] sm:text-[32px] leading-[150%] font-extrabold">
            Context-Aware Reasoning Technology
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-[626px_1fr] gap-x-[50px] justify-items-center lg:justify-items-start">
            <div className="">
              {[
                {
                  title: 'Meaning Based Reasoning',
                  desc: 'Unlike existing AI with training models, it can understand intentions based on the meaning in natural language.',
                  logoSrc: '/images/landing/index/educate-ai/meaning_icon.svg',
                },
                {
                  title: 'Transparent Reasoning',
                  desc: 'The entire reasoning process is completely transparent, which makes it easy to manage the knowledge base.',
                  logoSrc:
                    '/images/landing/index/educate-ai/reasoning_icon.svg',
                },
                {
                  title: 'Universally Applicable',
                  desc: 'Not only the AI can immediately learn your domain knowledge, but also it can expand to different contexts.',
                  logoSrc:
                    '/images/landing/index/educate-ai/universally_icon.svg',
                },
              ].map(({ title, desc, logoSrc }) => (
                <div key={title} className="mb-[24px]">
                  <div className="flex items-center mb-4">
                    <img
                      className="mr-5 w-[40px] h-[40px]"
                      src={logoSrc}
                      alt="icon"
                      width="40"
                      height="40"
                    />
                    <h3 className="text-base sm:text-[20px] font-bold">
                      {title}
                    </h3>
                  </div>

                  <p className="text-base sm:text-[16px] leading-6">{desc}</p>
                </div>
              ))}
            </div>
            <div className="max-w-[540px] mt-[18px] sm:mt-[40px] lg:mt-0 lg:max-w-none self-start pt-3 justify-center grid w-full">
              <img
                src="/images/landing/index/educate-ai/canonical_model.svg"
                alt="canonical model"
                className="mb-[40px]"
              />
              <div className="text-center">
                Unit of Reasoning: <strong>Canonical Model</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[40px] sm:py-[60px] lg:py-[80px]">
        <div className="container px-6 sm:px-8 xl:px-0 max-w-[1200px] mx-auto">
          <h2 className="mb-[30px] sm:mb-[50px] xl:mb-[60px] text-[24px] sm:text-[32px] leading-[150%] font-extrabold">
            Boost Your Business Efficiency
          </h2>
          {/* XL because content is too long */}
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-[80px] items-center">
            <div>
              <div className="index-cost-box pt-1.5">
                <CostItem
                  heading="80%"
                  title="Cut Business Cost"
                  content="Eliminate manual tasks by automating workflows"
                />

                <CostItem
                  heading="38%"
                  title="Increased Profitability"
                  content="Build your AI-powered business solutions"
                  arrowDirection="up"
                />

                <CostItem
                  heading="28%"
                  title="Achieve your business goals"
                  content="Provide tailored experience for specific needs"
                  needBottomMargin={false}
                />
              </div>
            </div>

            <div className="index-engine mt-[72px] sm:mt-[80px] xl:mt-0">
              <div className="index-engine-box flex justify-start items-center">
                <div className="box-section-wrapper">
                  <section className="grid py-0.5 items-center grid-cols-[80px_auto] sm:grid-cols-[82px_1fr] pl-6 gap-6 sm:gap-[60px] mb-[30px] sm:mb-[36px]">
                    <div
                      className={`person ${
                        isManpowerStart ? 'person-ani' : ''
                      }`}
                    >
                      <div className="person-wrapper">
                        <div className={`${isManpowerStart ? 'img-wrap' : ''}`}>
                          <img
                            src="/images/landing/index/engine/person-filled.svg"
                            alt="manpower infographics"
                          />
                        </div>

                        <div
                          className={`${isManpowerStart ? 'img-wrap2' : ''}`}
                        >
                          <img
                            src="/images/landing/index/engine/person.svg"
                            alt="manpower infographics"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="index-engine-box-number text-[24px] sm:text-[40px]">
                        <span>{manpowerInt}</span>
                        <span>%</span>
                      </div>
                      <span className="text-sm sm:text-[16px] leading-7">
                        Less manpower required
                      </span>
                    </div>
                  </section>

                  <section className="grid py-0.5 items-center grid-cols-[80px_auto] sm:grid-cols-[82px_1fr] pl-6 gap-6 sm:gap-[60px] mb-[30px] sm:mb-[36px]">
                    <div className={`square ${isAnimationStart && 'ani'}`} />
                    <div>
                      <div className="index-engine-box-number text-[24px] sm:text-[40px]">
                        <span>70%</span>
                      </div>
                      <span
                        className="text-sm sm:text-[16px] index-engine-box-desc-data leading-7"
                        ref={dataRef}
                      >
                        Repetitive workflows to be automated
                      </span>
                    </div>
                  </section>
                  <section className="grid py-0.5 items-center grid-cols-[80px_auto] sm:grid-cols-[82px_1fr] pl-6 gap-6 sm:gap-[60px] xl:gap-[60px]">
                    <div className="flex items-center">
                      <svg className="circle" viewBox="0 0 112 112">
                        <circle
                          className="circle-bg"
                          cx="56"
                          cy="56"
                          r="55.9"
                        />
                        {((screenWidth >= 712 && dataInt < 50) ||
                          (screenWidth < 712 && timeInt > 1)) && (
                          <circle
                            className={cn({
                              'circle-ani': [
                                screenWidth >= 712 && dataInt < 50,
                                screenWidth < 712 && timeInt > 1,
                              ].some(Boolean),
                            })}
                            r="28"
                            cx="56"
                            cy="56"
                            stroke="#D8D3D3"
                            strokeWidth="56"
                            fill="none"
                          />
                        )}
                      </svg>
                    </div>
                    <div>
                      <div className="index-engine-box-number text-[24px] sm:text-[40px]">
                        <span>{timeInt}</span>
                        <span>x</span>
                      </div>
                      <span className="text-sm sm:text-[16px] leading-7">
                        Faster development time
                      </span>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#4d5d6f]">
        <div className="container py-[40px] sm:py-[60px] lg:py-[80px] px-6 sm:px-8 lg:px-0 max-w-[1200px] mx-auto">
          <h2 className="mb-[30px] sm:mb-[56px] text-[24px] sm:text-[32px] leading-[150%] text-center text-white font-extrabold">
            Delightful Experience with HLI
          </h2>

          <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-9">
            <div className="head-feedback-block">
              <img
                src="/images/landing/index/main/developers.svg"
                alt="developer"
                height="72"
                width="72"
              />
              <div className="text-base my-3 text-white/[.8]">Developers</div>
              <p className="head-feedback-content">
                “Finally Found a Solution to Tackle{' '}
                <span className="font-bold">Complex Needs</span>.”
              </p>
            </div>
            <div className="head-feedback-block text-sm">
              <img
                src="/images/landing/index/main/business_owners.svg"
                alt="business_owners"
                height="72"
                width="72"
              />
              <div className="text-base my-3 text-white/[.8]">
                Business Owners
              </div>
              <p className="head-feedback-content text-sm">
                “Making Real <span className="font-bold">Positive</span>
                <br />
                <span className="font-bold">Returns</span> on Business.”
              </p>
            </div>
            <div className="head-feedback-block">
              <img
                src="/images/landing/index/main/managers.svg"
                alt="developer"
                height="72"
                width="72"
              />
              <div className="text-base my-3 text-white/[.8]">Managers</div>
              <p className="head-feedback-content text-sm">
                “Now I Channel our Team's
                <br />
                Efforts toward <span className="font-bold">Value</span>.”
              </p>
            </div>
            <div className="head-feedback-block">
              <img
                src="/images/landing/index/main/customers.svg"
                alt="developer"
                height="72"
                width="72"
              />
              <div className="text-base my-3 text-white/[.8]">Customers</div>
              <p className="head-feedback-content text-sm">
                “I Sense
                <br />
                <span className="font-bold">True Understanding</span>.”
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#fafafa]">
        <div className="container py-[40px] sm:py-[60px] lg:py-[80px] px-6 max-w-[1248px] mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-[348px_auto] gap-x-[60px]">
            <h2 className="mb-[30px] sm:mb-[40px] text-[24px] sm:text-[32px] leading-[150%] font-extrabold text-left sm:text-center lg:text-left">
              What our clients say about HLI
            </h2>

            <VillaMarketTestimonial />
          </div>
        </div>
      </div>
      <div className="container pt-[40px] pb-[90px] sm:py-[60px] px-6 sm:px-8 max-w-[1248px] mx-auto">
        <h2 className="mb-[30px] sm:mb-[40px] text-[24px] sm:text-[32px] leading-[150%] font-extrabold text-left sm:text-center lg:text-left">
          Real-World Business Cases with HLI
        </h2>

        <BasicSlider
          settings={{
            dots: true,
            infinite: true,
            // fade: true, // this somehow make cache on link button url
            speed: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  centerMode: false,
                  centerPadding: '0',
                },
              },
            ],
          }}
          buttonClasses="mr-[10px]"
        >
          {[
            {
              key: 'a-1',
              link: 'villa_2',
              comment:
                'Villa Market was committed to overcoming <span class="font-bold text-[#fff]">out-of-stock challenges</span> and delivering a <span class="font-bold text-[#fff]">flawless shopping experience</span> to every customer. ' +
                'As a result, they <span class="font-bold text-[#F7B716]">increased their profits by 25%</span> while simultaneously improving their customer experience.',
              breadcrumbs: [
                'Villa Market',
                'F&B / Retail',
                'Inventory Management',
              ],
              success_title: 'Human Logic Intelligence and Conversational AI',
              success_items: [
                {
                  title: 'Out-of-Stock Item Reduction Rate',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">68% +</span>',
                },
                {
                  title: 'Increase in Profit',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">25% +</span>',
                },
                {
                  title: 'Decision-making Time Saving',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">82% +</span>',
                },
              ],
            },
            {
              key: 'a-0',
              link: 'villa_1',
              comment:
                '“Users can <span class="font-bold text-[#FFB600]">significantly reduce the time</span> required to access information and follow suggested actions. Conversational AI can reduce the <span class="font-bold text-[#FFF]">managers’ workloads</span> and assist customers in receiving the <span class="font-bold text-[#FFF]">right information.</span>“',
              author: 'Varakorn Klaiklang, the Chief Technology Officer',
              breadcrumbs: ['Villa Market', 'Retail', 'Customer Service'],
              success_title: 'Human Logic Intelligence and Conversational AI',
              success_items: [
                {
                  title: 'Sessions Covered by AI',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">91% +</span>',
                  item_class: 'basis-full sm:basis-auto',
                },
                {
                  title: 'Customer Satisfaction',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">4.85 </span><span class="text-[12px] md:text-[16px] font-semibold">/5.0</span>',
                },
              ],
            },
            {
              key: 'a-2',
              link: 'industry_consultant_client',
              comment:
                'Implementing a <span class="font-bold text-[#FFB600]">Professional Advisor AI</span> that demonstrates the capability to completely replace humans-in-the-loop is a <span class="font-bold text-[#fff]">cost-effective</span> and <span class="font-bold text-[#fff]">scalable</span> solution. Moreover, AI can diagnose issues and provide solutions based on expert logic.',
              breadcrumbs: ['Industry Consultant Client', 'Expert Advisory'],
              success_title: 'Conversational AI',
              success_items: [
                {
                  title: 'Time Saving to Achieve Mastery',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">88% +</span>',
                  item_class: 'basis-full sm:basis-auto',
                },
                {
                  title: 'End-user Satisfaction',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">4.94 </span><span class="text-[12px] md:text-[16px] font-semibold">/5.0</span>',
                },
              ],
            },
            {
              key: 'a-3',
              link: 'real_estate_client',
              comment:
                'From project search to after-service interactions, Conversational AI enhances the <span class="font-bold text-[#FFB600]">customer journey</span> through insightful knowledge, repair services, marketing campaigns, and showroom bookings, fostering <span class="font-bold text-[#fff]">optimal decisions</span> and <span class="font-bold text-[#fff]">customer engagement</span>.',
              breadcrumbs: [
                'Real Estate Client',
                'Project Recommendation and Customer Support',
              ],
              success_title: 'Conversational AI',
              success_items: [
                {
                  title: 'Recommendation Rate',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">36% +</span>',
                },
                {
                  title: 'Booking Rate',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">21% +</span>',
                },
                {
                  title: 'Sessions Covered by AI',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">88% +</span>',
                },
              ],
            },
            {
              key: 'a-4',
              link: 'logistics_and_supply_chain_client',
              comment:
                'Stakeholders and investors have convenient access to <span class="font-bold text-[#fff]">timely information</span> and the <span class="font-bold text-[#FFB600]">company profile</span>, and they can arrange program schedules, all while the company reduces repetitive human efforts, through Conversational AI interface.',
              breadcrumbs: ['Logistics and Supply Chain Client', 'IR Solution'],
              success_title: 'Conversational AI',
              success_items: [
                {
                  title: 'Company Disclosure',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">64% +</span>',
                },
                {
                  title: 'Document Request',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">26% +</span>',
                },
              ],
            },
            {
              key: 'a-5',
              link: 'tourism_and_travel_client',
              comment:
                'From <span class="font-bold text-[#fff]">requesting travel information</span> to <span class="font-bold text-[#fff]">finalizing bookings</span>, our Conversational AI reduced mundane and repetitive tasks of employees, delivering <span class="font-bold text-[#FFB600]">a seamless customer experience</span> with higher performance that <span class="font-bold text-[#fff]">leads to purchases</span> without time limitations.',
              breadcrumbs: [
                'Tourism and Travel Client',
                'Travel and Flight Booking Assistant',
              ],
              success_title: 'Conversational AI',
              success_items: [
                {
                  title: 'Booking Rate via AI (vs. 15% + via Human Agent)',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">18% +</span>',
                },
                {
                  title: 'Employee Time and Cost Saved',
                  desc: '<span class="text-[18px] md:text-[28px] font-bold">10% +</span>',
                },
              ],
            },
          ].map(
            ({ key, author, comment, breadcrumbs, success_items, link }) => (
              <div className="text-[#fff]" key={key}>
                <div className="min-h-[428px] md:min-h-[480px] lg:min-h-[433px] rounded-[10px] sm:rounded-[20px] p-4 sm:p-[40px] feedback-block relative">
                  <ul className="ml-[-9px] mb-5 md:mb-6 text-xs md:text-[16px] feedback-breadcrumbs flex flex-wrap">
                    {breadcrumbs.map((breadcrumb, index) => (
                      <>
                        {index === 0 ? (
                          <li className="px-3 font-bold mb-2">{breadcrumb}</li>
                        ) : (
                          <li className="px-3 mb-2 font-light">{breadcrumb}</li>
                        )}
                      </>
                    ))}
                  </ul>
                  <div className="mb-[30px] sm:mb-[60px]">
                    <p
                      className="leading-[150%] text-[16px] md:text-[28px] font-light"
                      dangerouslySetInnerHTML={{ __html: comment }}
                    />
                    {author && (
                      <span className="mt-3 text-sm text-[#fff]/[.6]">
                        {author}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col gap-[30px] items-start lg:flex-row flex-wrap sm:justify-between lg:items-center lg:absolute left-4 sm:left-[40px] right-4 sm:right-[40px] bottom-4 sm:bottom-[40px]">
                    <div className="flex flex-wrap flex-col md:flex-row">
                      {success_items.map(
                        ({ title, desc, item_class = '' }, index) => (
                          <div
                            className={`${item_class} ${
                              success_items.length !== index + 1
                                ? 'mr-3 sm:mr-8 mb-3 sm:mb-0'
                                : ''
                            }`}
                          >
                            <div className="text-[#03B4DE] text-xs md:text-[16px] font-semibold mb-3 whitespace-pre-line leading-4 sm:leading-[22px]">
                              {title}
                            </div>
                            <div
                              className="leading-6"
                              dangerouslySetInnerHTML={{ __html: desc }}
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div className="mt-auto">
                      <Link href={`case-studies/details?case=${link}`}>
                        <a className="btn-read-more bg-transparent">
                          Learn More
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </BasicSlider>
      </div>

      <div className="bg-[#fafafa]">
        <div className="container pt-[40px] pb-[90px] sm:py-[60px] px-6 max-w-[1248px] mx-auto">
          <h2 className="mb-[30px] sm:mb-[40px] text-[24px] sm:text-[32px] leading-[150%] font-extrabold text-center">
            Our Human Logic{' '}
            <span className="block sm:inline">Intelligence Solutions</span>
          </h2>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[56px] gap-y-[40px] max-w-[956px] mx-auto">
            <div className="bg-[#f0e593] max-w-[455px] rounded-[20px] text-center sm:px-10 sm:py-12 px-4 py-9 mx-auto">
              <img
                className="mb-8 h-[175px] sm:h-[255px]"
                src="/images/landing/solutions/hli-x/main.svg"
                alt="HLI X"
              />
              <h2 className="mb-4 font-extrabold text-[20px] sm:text-[28px]">
                HLI-X
              </h2>
              <p className="text-[#3C4550] leading-6 mb-4">
                Accelerate real-world business performance by building a
                synergistic model with Human Logic Intelligence-X
              </p>
              <Link href="/solutions/hli-x">
                <a className="btn-learn-more">Learn More</a>
              </Link>
            </div>

            <div className="bg-[#b7e1e7] max-w-[455px] rounded-[20px] text-center sm:px-10 sm:py-12 px-4 py-9 mx-auto">
              <img
                className="mb-8 h-[175px] sm:h-[255px]"
                src="/images/landing/solutions/hli-automation/main.svg"
                alt="HLI Automation"
              />
              <h2 className="mb-4 font-extrabold text-[20px] sm:text-[28px]">
                HLI-Automate
              </h2>
              <p className="text-[#3C4550] leading-6 mb-4">
                Boost business productivity by automating business process by
                Human Logic Intelligence-Automate, designed for transformative
                business
              </p>
              <Link href="/solutions/hli-automate">
                <a className="btn-learn-more">Learn More</a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-6 sm:px-8 max-w-[1248px] py-[40px] sm:py-[60px] lg:py-[80px] mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[20px]">
          <div>
            <h2 className="text-center lg:text-left mb-[40px] sm:mb-[50px] text-[24px] sm:text-[32px] leading-[150%] font-extrabold">
              Questions You May Have
            </h2>
            <div className="flex justify-center lg:justify-start mb-[30px] sm:mb-[40px] lg:mb-0">
              <div
                className={`question-tab cursor-pointer h-[200px] w-[210px] rounded-2xl ${
                  questionTab === QUESTION_TAB.BUSINESS
                    ? 'bg-white active'
                    : 'bg-[#fafafa]'
                }`}
                onClick={() => setQuestionTab(QUESTION_TAB.BUSINESS)}
              >
                <img
                  src={`/images/landing/index/common-questions/business${
                    questionTab === QUESTION_TAB.BUSINESS ? '_active' : ''
                  }.svg`}
                  className="mb-5"
                  alt="icon"
                />
                <div
                  className={`text-center ${
                    questionTab === QUESTION_TAB.BUSINESS ? 'font-semibold' : ''
                  }`}
                >
                  Are you running
                  <br /> a business?
                </div>
              </div>
              <div
                className={`question-tab cursor-pointer h-[200px] w-[210px] ml-[30px] rounded-2xl ${
                  questionTab === QUESTION_TAB.DEVELOPER
                    ? 'bg-white active'
                    : 'bg-[#fafafa]'
                }`}
                onClick={() => setQuestionTab(QUESTION_TAB.DEVELOPER)}
              >
                <img
                  src={`/images/landing/index/common-questions/developer${
                    questionTab === QUESTION_TAB.DEVELOPER ? '_active' : ''
                  }.svg`}
                  className="mb-5"
                  alt="icon"
                />
                <div
                  className={`text-center ${
                    questionTab === QUESTION_TAB.DEVELOPER
                      ? 'font-semibold'
                      : ''
                  }`}
                >
                  Are you a<br /> developer?
                </div>
              </div>
            </div>
          </div>
          {questionTab === QUESTION_TAB.DEVELOPER && (
            <div>
              {[
                {
                  title: 'Can I request a demo?',
                  desc: `Yes, you can definitely request a demo of Mind Expression. It's a great way to explore how the platform works and understand its features in action. To request a demo, simply <a class='text-[#18aa78] font-bold' href='/contact'>contact us</a> and request a demo, and we'll be happy to guide you through the process.`,
                },
                {
                  title: 'Why go for Mind Expression as a developer?',
                  desc: 'Mind Expression offers developers unique advantages. Seamlessly integrate specific organizational knowledge into AI systems. Our reasoning engine understands diverse meanings and contexts, avoiding time-consuming training. Transparent reasoning simplifies debugging, showcasing AI comprehension during tests or business flows/conversations. Step into the world of Context Hopping empowered by logical reasoning, waving off intricate decision trees.',
                },
                {
                  title: 'I want to know more about how it works.',
                  desc: `Sure! Mind Expression simplifies the setup process for developers. You'll be able to define scopes and subjects within your business workflows, connect your data, and even test your AI solution in a controlled environment using the Sandbox feature. We empower you to analyze its performance and subsequently scale it to address various use cases. To dive deeper into understanding how Mind Expression works, you can refer to the <a class='text-[#18aa78] font-bold' href=${process.env.NEXT_PUBLIC_MINDX_DOC} target='_blank'>comprehensive documentation</a> available.`,
                },
              ].map(({ title, desc }, index) => (
                <div
                  key={title}
                  className="py-5"
                  style={{
                    borderBottom: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <h3
                    className="text-[16px] sm:text-[18px] font-semibold flex items-center justify-between cursor-pointer px-5"
                    onClick={() => handleQuestionClick('developer', index + 1)}
                  >
                    {title}{' '}
                    {showCommonQuestions.developer[index + 1] ? (
                      <BiChevronDown className="text-[24px] sm:text-[28px] shrink-0" />
                    ) : (
                      <BiChevronRight className="text-[24px] sm:text-[28px] shrink-0" />
                    )}
                  </h3>
                  {showCommonQuestions.developer[index + 1] && (
                    <div
                      className="text-sm sm:text-[16px] text-gray-darkest px-5 leading-8 mt-5"
                      dangerouslySetInnerHTML={{ __html: desc }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}

          {questionTab === QUESTION_TAB.BUSINESS && (
            <div>
              {[
                {
                  title: 'How do your prices work?',
                  desc: `Our pricing structure for Mind Expression is designed to provide flexibility and value. By <a class='text-[#18aa78] font-bold' href="${
                    userInfo ? '/contact' : '/start'
                  }">contacting us</a>, you'll have access to the complete suite of Mind Expression features, including Unlimited AI Scopes and Subjects, integration with different channels, prebuilt scopes. If you're interested in learning about custom plans for enterprise and partners, please don't hesitate to <a class='text-[#18aa78] font-bold' href='/contact'>contact us</a>.`,
                },
                {
                  title: 'What business impact can I expect?',
                  desc: "Embracing Mind Expression opens the door to a multitude of positive business outcomes. This includes streamlined automation processes, improved business performance, reduced operational costs. and enhanced customer experience management! Mind Expression is designed to empower businesses across various dimensions. Interested in discussing this further? <a class='text-[#18aa78] font-bold' href='/contact'>Reach out to our team</a> today!",
                },
                {
                  title: 'We want to know if the product is good for us.',
                  desc: "Certainly! If you're looking to determine whether Mind Expression is a good fit for your specific business needs, we encourage you to <a class='text-[#18aa78] font-bold' href='/contact'>get in touch</a> with us. Our team will be more than happy to engage in a discussion with you, understand your requirements, and provide guidance on how Mind Expression can benefit your business.",
                },
                {
                  title: 'How does your product work in business?',
                  desc: "Our product is tailored to address a wide range of business needs across various industries. We have an extensive portfolio and a team of experts who specialize in customizing the solution to fit your business requirements. If you have specific needs or unique challenges, please don't hesitate to <a class='text-[#18aa78] font-bold' href='/contact'>reach out to us</a>. We'll work closely with you to build a solution that aligns with your goals",
                },
              ].map(({ title, desc }, index) => (
                <div
                  key={title}
                  className="py-5"
                  style={{
                    borderBottom: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <h3
                    className="text-[16px] sm:text-[18px] font-semibold flex items-center justify-between cursor-pointer px-5"
                    onClick={() => handleQuestionClick('business', index + 1)}
                  >
                    {title}{' '}
                    {showCommonQuestions.business[index + 1] ? (
                      <BiChevronDown className="text-[24px] sm:text-[28px] shrink-0" />
                    ) : (
                      <BiChevronRight className="text-[24px] sm:text-[28px] shrink-0" />
                    )}
                  </h3>
                  {showCommonQuestions.business[index + 1] && (
                    <div
                      className="text-sm sm:text-[16px] text-gray-darkest px-5 leading-8 mt-5"
                      dangerouslySetInnerHTML={{ __html: desc }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="container px-6 sm:px-8 xl:px-0 max-w-[1200px] pt-0 lg:pt-6 mb-[-240px] relative z-1 mx-auto">
        <div
          className="rounded-[20px] p-5 sm:p-[36px] bg-white"
          style={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.03)' }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-[72px] gap-y-[28px] sm:gap-y-[56px]">
            {[
              {
                title: 'Tutorial Videos',
                desc: 'Discover product tips and tricks in the video tutorial',
                logoSrc: '/images/landing/index/learning/tutorial.png',
                linkUrl:
                  'https://www.youtube.com/channel/UCAmYDodFoCtdTPM_5NBgO5w',
                isExternalUrl: true,
              },
              {
                title: 'Instruction Manuals',
                desc: 'Learn everything about the product, components in detail',
                logoSrc: '/images/landing/index/learning/instruction.png',
                linkUrl: process.env.NEXT_PUBLIC_MINDX_DOC,
                isExternalUrl: true,
              },
              {
                title: 'Contact Us',
                desc: 'Try our demo session and join the conversation with us',
                logoSrc: '/images/landing/index/learning/solution.svg',
                linkUrl: '/contact',
                isExternalUrl: false,
              },
              {
                title: 'Request your AI',
                desc: 'Build your own AI and explore Mind Expression',
                logoSrc: '/images/landing/index/learning/demo.png',
                linkUrl: userInfo ? '/builder' : '/start',
                isExternalUrl: false,
              },
            ].map(({ title, desc, logoSrc, linkUrl, isExternalUrl }, index) => (
              <a
                key={title}
                href={linkUrl}
                target={isExternalUrl ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <div className="grid grid-cols-[50px_1fr] sm:grid-cols-[80px_2fr] lg:grid-rows-[68px_2fr] lg:grid-cols-1 gap-4 sm:gap-2 lg:gap-0  lg:text-center relative">
                  <div className="lg:mb-3 text-center">
                    <img
                      src={logoSrc}
                      alt="icon"
                      className="h-[40px] sm:h-[50px]"
                    />
                  </div>
                  <div className={` ${index !== 3 ? '' : ''} lg:mb-0`}>
                    <h3 className="text-[14px] sm:text-[18px] mb-1 sm:mb-3 font-bold hover:text-[#099ef6]">
                      {title}
                    </h3>
                    <p className="text-[12px] sm:text-[14px] leading-5">
                      {desc}
                    </p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          @use 'sass:math';
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .text-highlight {
            color: #099ef6;
          }

          .check-icon {
            background: url('#{$image_url}/landing/index/check_icon.svg') left
              center no-repeat;
            font-weight: 600;
            padding-left: 38px;
          }
          .question-tab {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &.active {
              border: 1px solid #099ef6;
            }
          }

          .feedback-breadcrumbs {
            li {
              position: relative;
            }
            li:not(:last-child):after {
              position: absolute;
              top: 5px;
              right: -1px;
              content: '';
              width: 1px;
              height: 8px;
              background-color: rgba(255, 255, 255, 0.2);
            }
          }
          .feedback-block {
            background: #3c4550 url('#{$image_url}/landing/index/big_quote.png')
              95% 50px no-repeat;

            @media all and (max-width: 768px) {
              background-size: 100px auto;
              background-position-y: 20px;
            }
          }

          .head-feedback-block {
            color: #fff;
            text-align: center;
            .head-feedback-content {
              font-size: 14px;
              line-height: 24px;
            }
          }

          .index-engine {
            &-box {
              &-number {
                font-weight: 700;
                color: #3c4550;
              }
            }

            @keyframes fill-person {
              0% {
                transform: translateX(0%);
              }
              100% {
                transform: translateX(-98%);
              }
            }

            @keyframes animateImage {
              0% {
                width: 100%;
              }

              0.001% {
                width: 100%;
              }

              100% {
                width: 0;
              }
            }
            .person {
              color: #4e6378;
              margin-top: 0;
              overflow: hidden;
              text-align: center;
              img {
                display: block;
              }
              .person-wrapper {
                position: relative;
                width: 82px;
                height: auto;
                overflow: hidden;
                img {
                  width: 82px;
                  height: auto;
                  max-width: initial;
                }
                @media all and (max-width: 639px) {
                  width: 60px;
                  img {
                    width: 60px;
                  }
                }

                .img-wrap2 {
                  opacity: 1;
                }
                .img-wrap {
                  position: absolute;
                  top: 0;
                  animation: animateImage 5s 0s;
                  overflow: hidden;
                  width: 0;
                }
              }
            }

            .person-ani {
              &:after {
                animation: fill-person 1.4s ease-in-out forwards;
              }
            }

            @keyframes decrease-square {
              0% {
                transform: translateX(0%);
              }
              100% {
                transform: translateX(-30%);
              }
            }

            .square {
              background: #d8d3d3;
              width: 82px;
              height: 52px;

              @media all and (max-width: 639px) {
                width: 60px;
                height: 38px;
              }

              border-radius: 12px;
              overflow: hidden;
              &:after {
                content: '';
                width: 100%;
                height: 100%;
                display: inherit;
                background: #03b4de;
                overflow: hidden;
              }
            }

            .ani {
              &:after {
                animation: decrease-square 1.4s ease-in-out forwards;
              }
            }

            $pie: 3.14;
            $angle: 360;
            $half: 180;
            $r: 56;
            $aim: $angle - 36;
            $result: math.div((2 * $pie * $r), $half) * $aim;

            .circle {
              width: 82px;
              height: 82px;
              transform: rotate(-90deg) rotateX(180deg);

              @media all and (max-width: 639px) {
                width: 60px;
                height: 60px;
              }

              &-ani {
                stroke-dasharray: 0 $result; /* 2π × 61 / 180 * 315 */
                stroke-dashoffset: 0;
                animation: animate_p1 1.4s ease-in-out forwards;
              }
            }

            @keyframes animate_p1 {
              to {
                stroke-dasharray: math.div($result, 4) $result;
              } /* 667 ÷ 4  */
            }
            .circle-bg {
              fill: #03b4de;
            }
            .circle-fill {
              stroke-dasharray: 0 667; /* 2π × 61 / 180 * 315 */
              stroke-dashoffset: 0;
              animation: animate_p1 3.2s ease-in-out forwards;
            }
          }

          .btn-idx-build {
            position: relative;
            display: inline-block;
            width: 200px;
            border-radius: 5px;
            border: 1px solid #099ef6;
            text-align: left;
            padding: 12px 20px;
            @include font(16px, 700, -0.4px, 22px, #099ef6);

            &.--btn-build-white {
              color: #4d5d6f;
              border-color: #fff;
              background-color: #fff;
              &:after {
                background: transparent
                  url('#{$image_url}/icons/ic_go_to_builder_gray.svg') center
                  center no-repeat;
              }
            }

            &:after {
              position: absolute;
              top: 10px;
              right: 20px;
              margin: auto;
              content: '';
              display: inline-block;
              height: 25px;
              width: 25px;
              background: transparent
                url('#{$image_url}/icons/ic_go_to_builder_blue.svg') center
                center no-repeat;
              background-size: 25px 25px;
              transition: right 0.3s ease-out;
            }

            @media (hover: hover) {
              &:hover {
                box-shadow: none;
                &:after {
                  right: 16px;
                }
              }
            }
          }

          .btn-learn-more {
            position: relative;
            display: inline-block;
            padding-right: 32px;
            border: none;
            height: 18px;
            background: transparent;
            text-align: left;
            @include font(16px, 700, -0.4px, 24px, #2b2c2d);

            &:after {
              position: absolute;
              top: 0;
              right: 0;
              margin: auto;
              content: '';
              display: inline-block;
              height: 25px;
              width: 24px;
              background: transparent
                url('#{$image_url}/landing/solutions/hli-x/bt_go_to_page.svg')
                center center no-repeat;
              background-size: 100%;
              transition: right 0.3s ease-out;
            }
            &:active {
              &:after {
                right: 8px;
              }
            }

            @media (hover: hover) {
              &:hover {
                &:after {
                  right: 8px;
                }
              }
            }

            &:active {
              &:after {
                right: 8px;
              }
            }
          }

          .btn-read-more {
            position: relative;
            display: inline-block;
            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 5px;
            height: 45px;
            width: 174px;
            padding: 0 20px;
            background: transparent;
            text-align: left;
            @include font(16px, 600, -0.4px, 40px, #ffffff);

            &:after {
              position: absolute;
              top: 10px;
              right: 20px;
              margin: auto;
              content: '';
              display: inline-block;
              height: 25px;
              width: 25px;
              background: transparent
                url('#{$image_url}/ic_go_to_builder@2x.svg') center center
                no-repeat;
              background-size: 25px 25px;
              transition: right 0.3s ease-out;
            }
            &:active {
              &:after {
                right: 8px;
              }
            }

            @media (hover: hover) {
              &:hover {
                &:after {
                  right: 16px;
                }
              }
            }

            &:active {
              &:after {
                right: 16px;
              }
            }
          }

          @media all and (min-width: 768px) {
            $head-min-height: 522px;

            .wrapper {
              $padding_index: 32px;
              padding: 0 $padding_index;
            }
          }

          @media all and (min-width: $resolution_d_min) {
            .wrapper {
              $padding_index: 162px;
              padding: 0 $padding_index;
            }

            $head-min-height: 602px;

            #btn-build {
              font-size: 18px;
              line-height: 24px;
            }
          }

          .main-section {
            position: relative;
            background-image: url('#{$image_url}/landing/index/banner_xs.png');
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: auto 100%;
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: -1;
              background-image: url('#{$image_url}/landing/index/banner_bg.png');
              background-repeat: repeat;
              // background-position: left top;
              background-size: auto 100%;
            }
          }

          @media all and (min-width: 361px) {
            .main-section {
              background-image: url('#{$image_url}/landing/index/banner_sm.png');
            }
          }
          @media all and (min-width: 768px) {
            .main-section {
              background-image: url('#{$image_url}/landing/index/banner.png');
            }
          }
        `}
      </style>
      <style jsx global>{`
        // to make it support bottom section of landing page.
        footer#mind-footer.mind-footer {
          padding-top: 200px !important;
        }
      `}</style>
    </div>
  );
};

const Index = ({ t, token }) => {
  const router = useRouter();
  const { query } = router;
  const { setUserToken } = useAuth();
  const ref = useRef();
  const [isUnAuthUser, setIsUnAuthUser] = useState(false);
  const [isAni, setIsAni] = useState(false);

  const onAuthPopupClicked = () => {
    setIsUnAuthUser(false);
    setIsAni(false);
    router.replace('/', '/', { shallow: true });
  };

  useEffect(() => {
    if (token) {
      setUserToken(token);
    }
  });

  useEffect(() => {
    if (query?.issue === 'builder') {
      setIsUnAuthUser(true);
    }

    return () => {
      setIsUnAuthUser(false);
    };
  }, [query]);

  useEffect(() => {
    if (ref?.current && isUnAuthUser) {
      setTimeout(() => {
        setIsAni(true);
      }, 0);
    }

    return () => {
      setIsAni(false);
    };
  }, [ref, isUnAuthUser]);

  return (
    <GlobalLayout pageTitle="" pageId="landing">
      <Head>
        <meta property="og:url" content="/" key="ogurl" />
      </Head>
      <div className="index-body relative z-10">
        <Content t={t} />
        {isUnAuthUser && (
          <Portal>
            <div className={`popup-auth ${isAni ? 'open' : ''}`}>
              <div className="popup-container">
                <div className="popup-wrapper">
                  <div className="popup-content">
                    <div ref={ref} className="content-wrapper">
                      <div className="warning-title">
                        You don&apos;t have access to the Builder
                      </div>
                      <div className="warning-desc">
                        We&apos;re sorry, the Builder cannot be accessed at this
                        time.
                        <br className="hide-mobile" />
                        If you want to gain access, or have any questions,
                        please{' '}
                        <a href={`mailto:${process.env.NEXT_PUBLIC_MINDX_SUPPORT_EMAIL}`} title="Contact us">
                          contact us
                        </a>
                        .
                      </div>
                      <div className="warning-btn">
                        <button className="grey" onClick={onAuthPopupClicked}>
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Portal>
        )}
      </div>
      <style jsx>
        {`
          @use 'sass:math';
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .index-body {
            padding-bottom: 132px;
          }
          .popup-auth {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100vh;
            z-index: 100000;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;

            &.open {
              .popup {
                &-bg {
                  opacity: 1;
                  transition: opacity 0.5s;
                }

                &-wrapper {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
            }

            .popup {
              &-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100vw;
                height: 100vh;
                z-index: 100000;
                display: flex;
                align-items: center;
                min-width: 100%;
                min-height: 100%;
                background: rgba(43, 44, 45, 0.5);
              }

              &-wrapper {
                position: relative;
                width: 100%;
                z-index: 100000;
                opacity: 0;
                transform: translateY(-30px);
                transition: transform 0.5s, opacity 0.3s;
              }

              &-content {
                position: relative;
                width: auto;
                display: flex;
                flex-direction: column;
                max-height: calc(100% - 0px);
                z-index: 100000;
                margin: 0px auto;
                overflow: hidden;
                .content-wrapper {
                  position: relative;
                  height: 100%;
                  width: 328px;
                  max-width: 414px;
                  padding: 224px 0 32px 0px;
                  margin: 0 auto;
                  max-height: calc(100vh - 48px);
                  display: flex;
                  flex-direction: column;
                  border-radius: 2px;
                  overflow: hidden;
                  background: $white
                    url('#{$image_url}/img_block_to_access_builder.jpg') center
                    top 32px no-repeat;
                  background-size: 328px 184px;
                }
              }
            }

            .warning-title {
              padding: 0 48px;
              @include font(20px, 700, -0.44px, 30px, #2b2c2d);
              text-align: center;
            }

            .warning-desc {
              margin-top: 8px;
              @include font(14px, 400, -0.3px, 22px, #2b2c2d);
              padding: 0 24px;
              text-align: center;

              .hide-mobile {
                display: none;
              }

              a {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.3px;
                font-weight: 600;
                color: $primary;

                @media (hover: hover) {
                  &:hover {
                    color: $primary_hover;
                  }
                }

                &:active {
                  color: $primary_hover;
                }
              }
            }

            .warning-btn {
              text-align: center;
              margin-top: 40px;
              button {
                display: inline-block;
                vertical-align: top;
                width: 144px;
                color: #2b2c2d;
                font-weight: 600;
                @media (hover: hover) {
                  &:hover {
                    color: #2b2c2d;
                  }
                }
                &:active {
                  color: #2b2c2d;
                }
              }
            }

            @media all and (min-width: 768px) {
              .popup {
                &-content {
                  .content-wrapper {
                    width: 620px;
                    max-width: 620px;
                    padding: 320px 0 40px 0;
                    background-position: center top 32px;
                    background-size: 500px 280px;
                  }
                }
              }

              .warning-title {
                padding: 0 40px;
                font-size: 24px;
                letter-spacing: -0.8px;
                line-height: 42px;
              }

              .warning-desc {
                font-size: 16px;
                line-height: 26px;
                letter-spacing: -0.4px;
                padding: 0 70px;
                a {
                  font-size: 16px;
                  line-height: 26px;
                  letter-spacing: -0.4px;
                }
                .hide-mobile {
                  display: block;
                }
              }

              .warning-btn {
                padding: 0 40px;
                text-align: right;
              }
            }
          }
        `}
      </style>
    </GlobalLayout>
  );
};

Index.propTypes = {
  token: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Index.defaultProps = {
  token: null,
};

Content.propTypes = {
  t: PropTypes.func.isRequired,
};

export const getServerSideProps = async ({ req, locale }) => {
  let returnProps = {};

  if (req) {
    const { body } = req;
    if ('body' in req && body) {
      const { auth_token: token } = body;
      if (token) {
        returnProps = {
          ...returnProps,
          token,
        };
      }
    }
  }

  returnProps = {
    ...returnProps,
    ...(await serverSideTranslations(locale, ['common', 'index'])),
  };

  return {
    props: returnProps,
  };
};

export default withTranslation('index')(Index);
